<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12"><h1>Privacy Policy</h1></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-justify">
        <p>
          Trepr exists to help build connections between people and make the world more open and
          inclusive. In short&mdash;to build a world where anyone can belong anywhere. We are a
          community built on trust. A fundamental part of earning that trust means being clear about
          how we use your information and protect your human right to privacy.
        </p>
        <p>
          This Privacy Policy describes how Trepr, Inc. and its affiliates
          (&ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or
          &ldquo;<strong>Trepr</strong>&rdquo;), process personal information that we collect
          through the Trepr Platform. Depending on where you live and what you are doing on the
          Trepr Platform, the supplemental privacy pages listed below may apply to you. Please
          follow the links and review the supplemental information provided there with information
          about how we process personal information for those regions and services.
        </p>
        <p><strong>DEFINITIONS</strong></p>
        <p>
          Where the definition of a term does not appear in this Privacy Policy (such as "Listing",
          "travel services", "Content," etc.), it shall be given its definition as outlined in our
          Terms of Service.
        </p>
        <p>
          "Aggregated Information" means information about all of our users or specific groups or
          categories of users that we combine together so that it no longer identifies or references
          an individual user.
        </p>
        <p>
          "Data Controller" means Trepr, the company responsible for the use and processing of
          Personal Information.
        </p>
        <p>
          "Personal Information" means information relating to a living individual who is or can be
          identified either from that information or from that information in conjunction with other
          information that is in, or is likely to come into, the possession of the Data Controller.
        </p>
        <p><strong>1. PERSONAL INFORMATION WE COLLECT</strong></p>
        <p><strong>1.1 Information needed to use the Trepr Platform</strong>.</p>
        <p>
          We collect personal information about you when you use the Trepr Platform &amp; Services.
          Without it, we may not be able to provide you with all services requested. This
          information includes:
        </p>
        <ul>
          <li>
            <p>
              <strong>Contact Information, Account, Profile Information</strong>. Such as your first
              name, last name, phone number, postal address, email address, date of birth, and
              profile photo, some of which will depend on the features you use.
            </p>
          </li>
          <li>
            <p>
              <strong>Identity Verification and Payment Information</strong>. Such as images of your
              government issued ID (as permitted by applicable laws), your ID number or
              other&nbsp;verification&nbsp;information, bank account or payment account information.
            </p>
          </li>
        </ul>
        <p><strong>1.2 Information you choose to give us</strong>.</p>
        <p>
          You can choose to provide us with additional personal information. This information may
          include:
        </p>
        <ul>
          <li>
            <p>
              <strong>Profile Information</strong>. Such as gender, preferred language(s), city, and
              personal description.
            </p>
          </li>
        </ul>
        <p>
          <strong
            >1.3 Information Automatically Collected by Using the Trepr Platform and our Payment
            Services</strong
          >.
        </p>
        <p>
          When you use the Trepr Platform and Payment Services, we automatically collect personal
          information. This information may include:
        </p>
        <ul>
          <li>
            <p>
              <strong>Geo-location Information</strong>. Such as precise or approximate location
              determined from your IP address or mobile device&rsquo;s GPS depending on your device
              settings. We may also collect this information when you&rsquo;re not using the app if
              you enable this through your settings or device permissions.
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Information</strong>. Such as the pages or content you view, searches
              for Listings, service requests you have made, and other actions on the Trepr Platform.
            </p>
          </li>
          <li>
            <p>
              <strong>Log Data and Device Information</strong>. Such as details about how
              you&rsquo;ve used the Trepr Platform (including if you clicked on links to third party
              applications), IP address, access dates and times, hardware and software information,
              device information, device event information, unique identifiers, crash data, cookie
              data, and the pages you&rsquo;ve viewed or engaged with before or after using the
              Trepr Platform.<span style="text-decoration: line-through"> </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Files and Media</strong>. Such as access to upload / choose images / documents
              for traveller and seekers service
            </p>
          </li>
          <li>
            <p><strong>Camera</strong>. to take / record media</p>
          </li>
        </ul>
        <p><strong>1.4 Personal Information We Collect from Third Parties</strong>.</p>
        <p>We collect personal information from other sources, such as:.</p>
        <ul>
          <li>
            <p>
              <strong>Third-Party Services</strong>. If you link, connect, or login to the Trepr
              Platform with a third party service (e.g. Google, Facebook, IOS), you direct the
              service to send us information such as your registration, friends list, and profile
              information as controlled by that service or as authorized by you via your privacy
              settings at that service.
            </p>
          </li>
          <li>
            <p>
              <strong>Background Information</strong>. For Members in the United States, to the
              extent permitted by applicable laws, we may obtain reports from public records of
              criminal convictions or sex offender registrations. For Members outside of the United
              States, to the extent permitted by applicable laws and with your consent where
              required, we may obtain the local version of police, background or registered sex
              offender checks. We may use your information, including your full name and date of
              birth, to obtain such reports.
            </p>
          </li>
          <li>
            <p>
              <strong>Enterprise Product Invitations and Account Management</strong>. Organizations
              that use our Enterprise products may submit personal information to facilitate account
              management and invitations to use enterprise products.
            </p>
          </li>
          <li>
            <p>
              <strong>Referrals and co-users</strong>. If you are invited to the Trepr Platform as a
              seeker or traveller to obtain or provide travel services, the person who invited you
              can submit personal information about you such as your email address, contact number
              or other contact information.
            </p>
          </li>
          <li>
            <p>
              <strong>Other Sources</strong>. To the extent permitted by applicable law, we may
              receive additional information about you, such as&nbsp;references, demographic data or
              information to help detect fraud and safety issues from third party service providers
              and/or partners, and combine it with information we have about you. For example, we
              may receive background check results or fraud warnings from identity verification
              service providers for use in our fraud prevention and risk assessment efforts. We may
              receive information about you and your activities on and off the Trepr Platform, or
              about your experiences and interactions from our partners. We may receive health
              information, including but not limited to health information related to contagious
              diseases.
            </p>
          </li>
        </ul>
        <p><strong>2. HOW WE USE INFORMATION WE COLLECT</strong></p>
        <p>
          <strong>2.1 Provide, Improve, and Develop the Trepr Platform</strong>. We use personal
          information to:
        </p>
        <ul>
          <li>
            <p>enable you to access the Trepr Platform and make and receive payments,</p>
          </li>
          <li>
            <p>enable you to communicate with other Members,</p>
          </li>
          <li>
            <p>perform analytics, debug and conduct research,</p>
          </li>
          <li>
            <p>provide customer service,</p>
          </li>
          <li>
            <p>send you messages, updates, security alerts, and account notifications,</p>
          </li>
          <li>
            <p>
              if you provide us with your contacts&rsquo; information such as your friends or
              co-travellers, we may process this information: (i) to facilitate your referral
              invitations, (ii) to share your trip details and facilitate trip planning, (iii) for
              fraud detection and prevention, and (iv) to facilitate your requests or for any other
              purpose you authorize,
            </p>
          </li>
          <li>
            <p>
              personalize and customize your experience based on your interactions with the Trepr
              Platform, your search and request history, your profile information and preferences,
              and other content you submit, and
            </p>
          </li>
          <li>
            <p>enable your use of our enterprise products.</p>
          </li>
        </ul>
        <p>
          <strong>2.2 Create and Maintain a Trusted and Safer Environment</strong>. We use personal
          information to:
        </p>
        <ul>
          <li>
            <p>
              detect and prevent fraud, spam, abuse, security and safety incidents, and other
              harmful activity,
            </p>
          </li>
          <li>
            <p>conduct security investigations and risk assessments,</p>
          </li>
          <li>
            <p>verify or authenticate information provided by you,</p>
          </li>
          <li>
            <p>
              conduct checks against databases and other information sources, including background
              or police checks,
            </p>
          </li>
          <li>
            <p>
              comply with our legal obligations, protect the health and well-being of our Seeker(s),
              Traveller(s) employees and members of the public,
            </p>
          </li>
          <li>
            <p>resolve disputes with our Members,</p>
          </li>
          <li>
            <p>enforce our agreements with third parties,</p>
          </li>
          <li>
            <p>
              comply with law, respond to legal requests, prevent harm and protect our rights (see
              section 4.5)
            </p>
          </li>
          <li>
            <p>
              enforce our&nbsp;<a href="https://www.airbnb.co.in/terms" aria-invalid="true"
                >Terms&nbsp;</a
              >and other policies (e.g.,&nbsp;Non-discrimination), and
            </p>
          </li>
          <li>
            <p>
              in connection with the activities above, we may conduct profiling based on your
              interactions with the Trepr Platform, your profile information and other content you
              submit to Trepr, and information obtained from third parties. In limited cases,
              automated processes could restrict or suspend access to the Trepr Platform if such
              processes detect activity that we think poses a safety or other risk to Trepr, our
              community, or third parties. If you would like to challenge the decisioning based on
              the automated process, please contact us via the Contact Information section below.
            </p>
          </li>
        </ul>
        <p>
          <strong
            >2.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing</strong
          >. We may use personal information to:
        </p>
        <ul>
          <li>
            <p>
              send you promotional messages, marketing, advertising, and other information based on
              your preferences and social media advertising through social media platforms,
            </p>
          </li>
          <li>
            <p>personalize, measure, and improve our advertising,</p>
          </li>
          <li>
            <p>
              administer referral programs, rewards, surveys, sweepstakes, contests, or other
              promotional activities or events sponsored or managed by Trepr or its third-party
              partners,
            </p>
          </li>
          <li>
            <p>
              analyze characteristics and preferences to send you promotional messages, marketing,
              advertising and other information that we think might be of interest to you, and
            </p>
          </li>
          <li>
            <p>invite you to events and relevant opportunities.</p>
          </li>
        </ul>
        <p>
          <strong>2.4 Provide Payment services</strong>. Personal information is used to enable or
          authorize third parties to use Payment Services:
        </p>
        <ul>
          <li>
            <p>Detect and prevent money laundering, fraud, abuse, security incidents.</p>
          </li>
          <li>
            <p>Conduct security investigations and risk assessments.</p>
          </li>
          <li>
            <p>Comply with legal obligations (such as anti-money laundering regulations).</p>
          </li>
          <li>
            <p>Enforce the&nbsp;Payment Terms&nbsp;and other payment policies.</p>
          </li>
          <li>
            <p>
              With your consent, send you promotional messages, marketing, advertising, and other
              information that may be of interest to you based on your preferences.
            </p>
          </li>
          <li>
            <p>Provide and improve the Payment Services.</p>
          </li>
        </ul>
        <p><strong>3. SHARING &amp; DISCLOSURE</strong></p>
        <p><strong>3.1 Sharing Between Members.</strong></p>
        <p>
          To help facilitate service requests or other interactions between Members, we may need to
          share certain information such as:
        </p>
        <ul>
          <li>
            <p>
              <a name="OLE_LINK1"></a><a name="OLE_LINK2"></a><a name="OLE_LINK3"></a
              ><a name="OLE_LINK4"></a> When a service request is made or dispute is submitted,
              certain information may be shared between Seeker(s) and Traveller(s), including
              profile, name, names of any additional Seekers, cancellation history, review
              information, age of Seeker (unless prohibited by applicable law), dispute outcome
              (when applicable) and other information you choose to share and submit. When a service
              request is confirmed, additional information is shared to assist with coordinating the
              trip, like profile photo and phone number. When you as a Traveller have a confirmed
              service request, certain information is shared with the Seeker (and the additional
              Seekers they invite, if applicable) to coordinate the travel service, such as your
              profile, full name, phone number, and Listing address.
            </p>
          </li>
          <li>
            <p>
              When you as a Traveller invite another Member to travel with you, you authorize that
              person to access and update your information and Member Content, including but not
              limited to certain information like your full name, phone number, calendar, listing
              information, Listing photos, and email address.
            </p>
          </li>
          <li>
            <p>
              When you as a Seeker invite others to a travel service, your full name, travel dates,
              Traveller name, listing details, and other related information will be shared with
              each additional user.
            </p>
          </li>
        </ul>
        <p>
          <strong
            >3.2 Information You Publish in Profiles, Listings, and other Public Information</strong
          >.
        </p>
        <p>You can make certain information publicly visible to others, such as:</p>
        <ul>
          <li>
            <p>
              Your public profile page, which includes your profile photo, first name, description,
              and city.
            </p>
          </li>
          <li>
            <p>
              Listing pages that include information such as the origin address or destination
              address approximate or precise location description, calendar availability, profile
              photo, aggregated demand information (like page views over a period of time), and
              additional information you choose to share.
            </p>
          </li>
          <li>
            <p>Reviews, ratings and other public feedback.</p>
          </li>
          <li>
            <p>Content in a community or discussion forum, blog or social media post.</p>
          </li>
        </ul>
        <p>
          We may display parts of your public profile and other Content you make available to the
          public like Listing details on third-party sites, platforms and apps.
        </p>
        <p>
          Information you share publicly on the Trepr Platform may be indexed through third-party
          search engines. In some cases, you may opt-out of this feature in your account settings.
        </p>
        <p>
          <strong
            >3.3 Complying with Law, Responding to Legal Requests, Preventing Harm and Protecting
            our Rights</strong
          >.
        </p>
        <p>
          We may disclose your information to courts, law enforcement, governmental or public
          authorities, tax authorities, or authorized third parties, if and to the extent we are
          required or permitted to do so by law or where disclosure is reasonably necessary: (i) to
          comply with our legal obligations, (ii) to comply with a valid legal request or to respond
          to claims asserted against Trepr, (iii) to respond to a valid legal request relating to a
          criminal investigation to address alleged or suspected illegal activity, or to respond to
          or address any other activity that may expose us, you, or any other of our users to legal
          or regulatory (iv) to enforce and administer our&nbsp;<a
            href="https://www.airbnb.co.in/terms"
            aria-invalid="true"
            >agreements</a
          >&nbsp;with Users, or (v) to protect the rights, property or personal safety of Trepr, its
          employees, its Members, or members of the public. For example, if permitted due to the
          forgoing circumstances, Traveller&rsquo;s tax information may be shared with tax
          authorities or other governmental agencies.
        </p>
        <p>
          Where appropriate, we may notify Members about legal requests unless: (i) providing notice
          is prohibited by the legal process itself, by court order we receive, or by applicable
          law, or (ii) we believe that providing notice would be futile, ineffective, create a risk
          of injury or bodily harm to an individual or group, or create or increase a risk of fraud
          upon or harm to Trepr, our Members, or expose Trepr to a claim of obstruction of justice.
        </p>
        <p>
          For jurisdictions where Trepr facilitates the collection and remittance of Taxes where
          legally permissible according to applicable law, we may disclose Travellers&rsquo; and
          Seekers&rsquo; information about transactions, service requests, service taxes to the
          applicable tax authority, such as Traveller and Seeker names, transaction dates and
          amounts, tax identification number(s), the amount of taxes received (or due) by Travellers
          from Seekers, and contact information.
        </p>
        <p>
          In jurisdictions where Trepr facilitates or requires a registration, notification, permit,
          or license application of a Traveller with a local governmental authority through Trepr in
          accordance with local law, we may share information of participating Travellers with the
          relevant authority, both during the application process and, periodically thereafter, such
          as the Traveller&rsquo;s full name and contact details, tax identification number, listing
          details, and services requested.
        </p>
        <p><strong>3.4 Service Providers</strong>.</p>
        <p>
          We share personal information with affiliated and unaffiliated service providers to help
          us run our business, including service providers that help us: (i) verify your identity or
          authenticate your identification documents, (ii) check information against public
          databases, (iii) conduct background or police checks, fraud prevention, and risk
          assessment, (iv) perform product development, maintenance and debugging, (v) allow the
          provision of the Trepr Services through third-party platforms and software tools (e.g.
          through the integration with our APIs), (vi) provide customer service, advertising, or
          payments services, (vii) process, handle or assess insurance claims or similar claims, or
          (viii) facilitate non-profit and charitable activities consistent with Trepr&rsquo;s
          mission. These providers are contractually bound to protect your personal information and
          have access to your personal information to perform these tasks.
        </p>
        <p><strong>3.5 Business Transfers</strong>.</p>
        <p>
          If Trepr undertakes or is involved in any merger, acquisition, reorganization, sale of
          assets, bankruptcy, or insolvency event, then we may sell, transfer or share some or all
          of our assets, including your information in connection with such transaction or in
          contemplation of such transaction (e.g., due diligence). In this event, we will notify you
          before your personal information is transferred and becomes subject to a different privacy
          policy.
        </p>
        <p><strong>3.6 Corporate Affiliates</strong>.</p>
        <p>
          To support us in providing, integrating, promoting and improving the Trepr Platform,
          Payment Services, and our affiliates&rsquo; services, we may share personal information
          within our corporate family of companies that are related by common ownership or control.
          Some examples are:
        </p>
        <ul>
          <li>
            <p>
              <strong>Sharing with Trepr, Inc</strong>. Even if your country of residence is not the
              United States, your information will be shared with Trepr, Inc. which provides the
              technical infrastructure for the Trepr Platform.
            </p>
          </li>
          <li>
            <p>
              <strong>Sharing with Trepr Payments</strong>. In order to facilitate payments on or
              through the Trepr Platform, will be shared with the relevant Trepr Payments entity.
            </p>
          </li>
          <li>
            <p>
              <strong>Sharing with Trepr Ireland</strong>. Even if your country of residence is the
              United States, Japan or China, your information may be shared with Trepr Ireland which
              provides customer support and other business operation services to other Trepr
              entities.
            </p>
          </li>
          <li>
            <p>
              <strong>Sharing with Trepr GSL</strong>. Even if your country of residence is not
              Japan, your information may be shared with Trepr GSL which provides customer support
              and other business operation services to other Trepr entities.
            </p>
          </li>
          <li>
            <p>
              <strong>Sharing with Trepr China</strong>. Even if your country of residence is not
              China, some of your information will be shared with Trepr China in the following
              circumstances:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Public data</strong>. Information you share publicly on the Trepr
                  Platform.
                </p>
              </li>
              <li>
                <p>
                  <strong>Creating a Listing</strong>. If you create a Listing in China, information
                  shared includes: (i) your name, phone number, email address, and passport/ID
                  details, (ii) information relating to the transportation listing (iii) service
                  request and travel information relating to the Listing, such as Seeker details as
                  set out below in Travel Services, dates, time and payment amounts, and (iv)
                  messages between you and prospective and confirmed Seekers.
                </p>
              </li>
              <li>
                <p>
                  <strong>Travel Services</strong>. If you book a Travel Service located in China,
                  information shared includes: (i) your name, phone number, and email address (ii)
                  service request and travel information, including dates and time, (iii) messages
                  between the Traveller and you or other Seekers on the same service request, and
                  (iv) the names, nationalities, gender, date of birth, and passport/ID details of
                  any Seekers.
                </p>
              </li>
              <li>
                <p>
                  <strong>Sending Messages</strong>. If you send a message to a Traveller in
                  relation to that Traveller&rsquo;s Listing in China, information shared includes
                  your name, profile picture and message content.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          The data sharing described above is necessary for the performance of the contract between
          you and us to enable you to list or book Travel Services in China and connect with Members
          in China, and vice versa. Where required under law or if you have expressly granted
          permission, Trepr China will disclose your information to Chinese government agencies
          without further notice to you. We&rsquo;ll notify you in advance in specific situations
          where we apply any practices that differ from what is described in this Privacy Policy
          (including practices pertaining to disclosures to government agencies).
        </p>
        <p><strong>4. OTHER IMPORTANT INFORMATION</strong></p>
        <p><strong>4.1 Analyzing your Communications</strong>.</p>
        <p>
          We may review, scan, or analyze your communications on the Trepr Platform for reasons
          outlined in the &ldquo;How We Use Information We Collect&rdquo; section of this policy,
          including fraud prevention, risk assessment, regulatory compliance, investigation, product
          development, research, analytics, and customer support purposes. For example, as part of
          our fraud prevention efforts, we scan and analyze messages to mask contact information and
          references to other sites. In some cases, we may also scan, review, or analyze messages to
          debug, improve, and expand product offerings. We use automated methods where reasonably
          possible. Occasionally we may need to manually review communications, such as for fraud
          investigations and customer support, or to assess and improve the functionality of these
          automated tools. We will not review, scan, or analyze your messaging communications to
          send third-party marketing messages to you and we will not sell reviews or analyses of
          these communications.
        </p>
        <p><strong>4.2 Linking Third-Party Accounts</strong>.</p>
        <p>
          You can link your Trepr account with certain third-party services like social networks.
          Your contacts on these third-party services are referred to as &ldquo;Friends.&rdquo; When
          you direct the data sharing by creating this link:
        </p>
        <ul>
          <li>
            <p>
              some of the information provided to us from linking accounts may be published on your
              public profile,
            </p>
          </li>
          <li>
            <p>
              your activities on the Trepr Platform may be displayed to your Friends on the Trepr
              Platform and/or that third-party service,
            </p>
          </li>
          <li>
            <p>
              a link to your public profile on that third-party service may be included in your
              Trepr public profile,
            </p>
          </li>
          <li>
            <p>
              other Trepr users may be able to see any Friends that you may have in common with
              them, or that you are a Friend of their Friend if applicable,
            </p>
          </li>
          <li>
            <p>
              other Trepr users may be able to see any schools, hometowns or other groups you have
              in common with them as listed on your linked social networking service,
            </p>
          </li>
          <li>
            <p>
              information you provide to us from the linking of your accounts may be stored,
              processed and transmitted for fraud prevention and risk assessment purposes, and
            </p>
          </li>
          <li>
            <p>
              publication and display of information that you provide to the Trepr Platform through
              this linkage is subject to your settings and authorizations on the Trepr Platform and
              the third-party service.
            </p>
          </li>
        </ul>
        <p><strong>4.3 Third-Party Partners &amp; Integrations</strong>.</p>
        <p>
          Parts of Trepr may link to third-party services, not owned or controlled by Trepr, such as
        </p>
        <p>
          <a name="OLE_LINK5"></a><a name="OLE_LINK6"></a><a name="OLE_LINK7"></a
          ><a name="OLE_LINK8"></a> <strong>4.3.1 Google Maps/Earth</strong> : Use of this services
          is subject to the privacy policies of those providers, such as&nbsp;<a
            href="https://www.google.com/intl/en_us/help/terms_maps.html"
            ><span style="text-decoration: underline"
              >Google Maps/Earth Additional Terms of Use</span
            ></a
          >,&nbsp;<a href="https://www.google.com/privacy.html"
            ><span style="text-decoration: underline">Google Privacy Policy</span></a
          >
          &amp; &nbsp;(see&nbsp;<a href="https://policies.google.com/technologies/partner-sites"
            ><span style="text-decoration: underline">here</span></a
          >&nbsp;for more information on how Google uses information). Trepr does not own or control
          these third parties and when you interact with them you are providing your information to
          them.
        </p>
        <p>
          <strong>4.3.2 Stripe Payment Gateways :</strong>Use of this services is subject to the
          privacy policies of providers, such as&nbsp;<span style="text-decoration: underline"
            ><a href="https://stripe.com/in/privacy">Stripe Payment Gateways Privacy</a></span
          >
          &amp; &nbsp;(see&nbsp;<a href="https://stripe.com/in/privacy"
            ><span style="text-decoration: underline">here</span></a
          >&nbsp;for more information on how Stripe Payment gateways uses information).
        </p>
        <ul>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#overview">Overview</a></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#personal-data-we-collect"
                  >Personal Data Stripe Collect</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#how-we-use-personal-data"
                  >How Stripe User Personal Data</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#how-we-disclose-personal-data"
                  >How Stripe Disclose Personal Data</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#your-rights-and-choices"
                  >Your Rights and Choices</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#security-and-retention"
                  >Security and Retention</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#international-data-transfers"
                  >International Data Transfers</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#use-by-minors">Use by Minors</a></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="../../../home/loi/Downloads/Updates%20To%20this%20Privacy%20Policy"
                  >Updates To this Privacy Policy</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#links-to-other-online-services"
                  >Links To Others Websites</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a
                  href="https://stripe.com/in/privacy#controllers-and-jurisdiction-specific-provisions"
                  >Controllers and Jurisdiction-specific Provisions</a
                ></span
              >
            </p>
          </li>
          <li>
            <p>
              <span style="text-decoration: underline"
                ><a href="https://stripe.com/in/privacy#contact-us">Stripe Support</a></span
              >
            </p>
          </li>
        </ul>
        <p><strong>5. YOUR RIGHTS</strong></p>
        <p>
          You can exercise any of the rights described in this section consistent with applicable
          law. Please note that we may ask you to verify your identity and request before taking
          further action on your request..
        </p>
        <p><strong>5.1 Managing Your Information</strong>.</p>
        <p>
          You can access and update some of your personal information through your Account settings.
          If you connected your Trepr Account to a third-party service, like Facebook or Google, you
          can change your settings and unlink from that service in your Account settings. You are
          responsible for keeping your personal information up to date.
        </p>
        <p><strong>5.2 Data Access and Portability</strong>.</p>
        <p>
          In some jurisdictions, applicable law may entitle you to request certain copies of your
          personal information or information about how we handle your personal information, request
          copies of personal information that you have provided to us in a structured, commonly
          used, and machine-readable format, and/or request that we transmit this information to
          another service provider (where technically feasible).
        </p>
        <p><strong>5.3 Data Erasure</strong>.</p>
        <p>
          In certain jurisdictions, you can request that your personal information be deleted.
          Please note that if you request the erasure of your personal information:
        </p>
        <ul>
          <li>
            <p>
              We may retain your personal information as necessary for our legitimate business
              interests, such as prevention of money laundering, fraud detection and prevention, and
              enhancing safety. For example, if we suspend an Trepr Account for fraud or safety
              reasons, we may retain information from that Trepr Account to prevent that Member from
              opening a new Trepr Account in the future.
            </p>
          </li>
          <li>
            <p>
              We may retain and use your personal information to the extent necessary to comply with
              our legal obligations. For example, Trepr and Trepr Payments may keep information for
              tax, legal reporting and auditing obligations.
            </p>
          </li>
          <li>
            <p>
              Information you have shared with others (e.g., Reviews, forum postings) will continue
              to be publicly visible on Trepr, even after your Trepr Account is cancelled. However,
              attribution of such information to you will be removed. Some copies of your
              information (e.g., log records) will remain in our database, but are disassociated
              from personal identifiers.
            </p>
          </li>
          <li>
            <p>
              Because we take measures to protect data from accidental or malicious loss and
              destruction, residual copies of your personal information may not be removed from our
              backup systems for a limited period of time.
            </p>
          </li>
        </ul>
        <p><strong>6. SECURITY</strong></p>
        <p>
          While no organization can guarantee perfect security, we are continuously implementing and
          updating administrative, technical, and physical security measures to help protect your
          information against unauthorized access, loss, destruction, or alteration.
        </p>
        <p><strong>7. CHANGES TO THIS PRIVACY POLICY</strong></p>
        <p>
          We reserve the right to modify this Privacy Policy at any time in accordance with
          applicable law. If we do so, we will post the revised Privacy Policy and update the
          &ldquo;Last Updated&rdquo; date at the top. If you disagree with the revised Privacy
          Policy, you can cancel your Account. If you do not cancel your Account before the date the
          revised Privacy Policy becomes effective, your continued access to or use of the Trepr
          Platform will be subject to the revised Privacy Policy.
        </p>
        <p><strong>8. GOT FEEDBACK?</strong></p>
        <p>
          Your opinion matters to us! If you'd like to provide feedback to us about this Privacy
          Policy, please email us at contact@trepr.com.
        </p>
        <p>
          We endeavour to keep your information accurate, complete and up to date. If your Personal
          Information that we hold is inaccurate, please let us know and we will make the necessary
          amendments, erase or block the relevant information and notify you within 60 days of your
          valid request that the relevant action has been taken.
        </p>
        <p>
          You may also request the erasure of your personal data if you believe we are otherwise in
          breach of relevant data protection legislation. All requests should be addressed to our
          Data Protection Compliance Officer at contact@trepr.com. There may be charges for making
          such a request.
        </p>
        <p><strong>9. COOKIE POLICY</strong></p>
        <p>
          Trepr uses "cookies" in conjunction with the Platform to obtain information. A cookie is a
          small data file that is transferred to your device (e.g., your phone or your computer) for
          record-keeping purposes. For example, a cookie could allow the Platform to recognize your
          browser, while another could store your preferences and other information.
        </p>
        <p>
          Your browser may allow you to set how it handles cookies, such as declining all cookies or
          prompting you to decide whether to accept each cookie. But please note that some parts of
          the Platform may not work as intended or may not work at all without cookies.
        </p>
        <p><strong>10. TREPR COOKIES AND THIRD PARTY COOKIES</strong></p>
        <p>
          Trepr may place our cookies on your device via the Platform. Accordingly, our Privacy
          Policy will apply to our treatment of the information we obtain via our cookies.
        </p>
        <p>
          We may also allow our business partners to place cookies on your device. For example, we
          use Google Analytics for web analytics, and so Google may also set cookies on your device.
          As further explained below, third parties may also place cookies on your device for
          advertising purposes.
        </p>
        <p>
          There are two types of cookies used on the Platform, namely "persistent cookies" and
          "session cookies".
        </p>
        <p>
          Session cookies will normally expire when you close your browser, while persistent cookies
          will remain on your device after you close your browser, and can be used again the next
          time you access the Platform.
        </p>
        <p><strong>11. OTHER TECHNOLOGIES</strong></p>
        <p>
          The Platform may also use other technologies with similar functionality to cookies, such
          as web beacons and tracking URLs to obtain Log Data about users. We may also use web
          beacons and tracking URLs in our messages to you to determine whether you have opened a
          certain message or accessed a certain link.
        </p>
        <p><strong>12. USES FOR TREPR COOKIES</strong></p>
        <p>Trepr uses cookies for a number of purposes, such as the following:</p>
        <ul>
          <li>
            <p>
              to enable, facilitate and streamline the functioning of the Platform across different
              webpages and browser sessions.
            </p>
          </li>
          <li>
            <p>to simplify your access to and use of the Platform and make it more seamless.</p>
          </li>
          <li>
            <p>
              to monitor and analyze the performance, operation and effectiveness of the Platform,
              so that we can improve and optimize it.
            </p>
          </li>
          <li>
            <p>
              to show you content (which may include advertisements) that is more relevant to you.
            </p>
          </li>
        </ul>
        <p><strong>13. USES FOR THIRD PARTY COOKIES</strong></p>
        <p>
          <a name="_GoBack"></a> Our partners' cookies are intended to obtain information to help
          them provide services to Trepr. For example, third party companies and individuals we
          engage to provide services to us may track your behaviour on our Platform to market and
          advertise Trepr listings or services to you on the Platform and third party websites.
          Third parties that use cookies and other tracking technologies to deliver targeted
          advertisements on our Platform and/or third party websites may offer you a way to prevent
          such targeted advertisements by opting-out at the websites of industry groups such as the
          Network Advertising Initiative (<a href="http://www.networkadvertising.org/choices/)"
            >http://www.networkadvertising.org/choices/)</a
          >
          and/or the Digital Advertising Alliance (<a href="http://www.aboutads.info/choices/)"
            >http://www.aboutads.info/choices/)</a
          >. You may also be able to control advertising cookies provided by publishers, for example
          Google's Ad Preference Manager (<a href="https://www.google.com/settings/ads/onweb/)"
            >https://www.google.com/settings/ads/onweb/)</a
          >. Please note that even if you choose to opt-out of receiving targeted advertising, you
          may still receive advertising on the Platform &acirc;&euro;&ldquo; it just will not be
          tailored to your interests.
        </p>
        <p>
          In addition, Facebook places a cookie via the Platform that allows Facebook to obtain
          aggregated, non-Personal Information to optimize their services. For example, if a user
          clicks on an advertisement for the Trepr mobile app on Facebook and subsequently installs
          the app, this cookie will inform Facebook that a user (who is not personally identified)
          has installed the app after clicking on the advertisement. This cookie may also inform
          Facebook that a user is using the app, without identifying the specific actions taken by
          the user in the app.
        </p>
        <p><strong>14. DISABLING COOKIES</strong></p>
        <p>
          <a name="OLE_LINK9"></a><a name="OLE_LINK10"></a><a name="OLE_LINK11"></a
          ><a name="OLE_LINK12"></a> Most browsers automatically accept cookies, but you can modify
          your browser setting to decline cookies by visiting the Help portion of your browser's
          toolbar. If you choose to decline cookies, please note that you may not be able to sign
          in, customize, or use some of the interactive features of the Platform. Flash cookies
          operate differently than browser cookies, and cookie management tools available in a web
          browser will not remove flash cookies. To learn more about how to manage flash cookies,
          you can visit the Adobe website (<span style="text-decoration: underline"
            ><a href="http://www.adobe.com/">http://www.adobe.com/</a></span
          >) and make changes at the Global Privacy Settings Panel (<span
            style="text-decoration: underline"
            ><a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html"
              >Get flash player</a
            ></span
          >).
        </p>
        <p><strong>15. CHANGES TO THIS COOKIE POLICY</strong></p>
        <p>
          We can change this Cookie Policy at any time. If we make material changes to the Cookie
          Policy, we will let you know either by posting the changed Cookie Policy on the Platform
          or by sending you an email.
        </p>
        <p>
          <a name="OLE_LINK13"></a><a name="OLE_LINK14"></a> It's important that you review the
          changed Cookie Policy. If you do not wish to agree to the changed Cookie Policy, then we
          cannot continue to provide the Platform to you, and your only option is to stop accessing
          the Platform and deactivate your Trepr account. You can find out more about how to
          deactivate your Trepr account at
          <span style="text-decoration: underline"
            ><a href="http://www.trepr.com/faq.">www.trepr.com/faq.</a></span
          >
        </p>
        <p><strong>16. CONTACT INFORMATION AND RESPONSIBLE TREPR ENTITIES</strong></p>
        <p>
          For questions or complaints about this Privacy Policy or Trepr&rsquo;s handling of
          personal information
        </p>
        <p>c/o Trepr, Ltd.<br />Northern Ireland,<br />United Kingdom<br />contact@trepr.com</p>
        <p><br /><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  created() {
    document.title = 'Trepr - Privacy | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Read the following to learn more about our Privacy Policy.This Policy applies to  Trepr websites.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, Trepr Privacy,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>

<style scoped></style>
